<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="mb-2 body">{{ address.firstname }} {{ address.lastname }}</p>
        <p class="mb-2 body" v-if="address.streetNoOne">
          {{ address.streetNoOne
          }}<span v-if="address.streetNoTwo">, {{ address.streetNoTwo }}</span>
        </p>
        <p class="mb-2 body" v-else-if="address.street">
          {{ address.street[0]
          }}<span v-if="address.street[1]">, {{ address.street[1] }}</span>
        </p>
        <p class="mb-2 body">
          {{ address.city }},
          <span v-if="stateName.length">{{ stateName[0].label }} - </span>
          {{ address.postcode }}
        </p>
        <p class="mb-2 body">
          <span v-if="address.countryId">{{ address.countryId }}</span>
        </p>
        <p class="mb-2 body">
          T:<a href="tel:"> {{ address.telephone }}</a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
import { fetchStates } from "@/services/util.service";
const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField",
});
/*eslint-disable*/
export default {
  name: "BillingAddressComponent",
  props: ["address"],
  components: {},
  data: () => ({
    stateName: [],
  }),
  computed: {
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    verifyAddress: function () {
      if (this.userAuth) {
        return this.$store.state.accountOne.address;
      } else {
        return this.$store.state.guestInfo.address;
      }
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    },
    allCountries: function () {
      return this.$store.state.accountOne.allCountries.map((countries) => {
        return {
          value: countries.id,
          label: countries.fullNameEnglish,
        };
      });
    },
    ...mapFields(["one"]),
  },
  watch: {},
  methods: {},
  created() {
    if (this.address) {
      this.stateNameList = fetchStates(
        this.address.countryId,
        this.countryAndStatesList
      );
      this.stateName = this.stateNameList.filter((obj) => {
        if (obj.value == this.address.regionId) return obj;
      });
    }
  },
};
</script>
