<template>
  <div v-if="userDetails">
    <v-form ref="shippingAddressform" v-model="valid" :lazy-validation="lazy">
      <v-row no-gutters v-if="!this.userAuth">
        <v-col cols="12">
          <p class="text-uppercase">
            already registered?
            <span class="text-uppercase ml-2">click here to login</span>
          </p>
          <v-text-field label="Email Address" ref="emailAddress" variant="outlined" density="compact" :rules="emailRules"></v-text-field>
          <span>You can create an account after checkout.</span>
          <v-checkbox v-model="newsletter" label="Subscribe to our newsletter" color="indigo"></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="py-1" v-if="hideAndShowFields">
          <v-text-field
            label="First Name"
            ref="firstName"
            v-model="firstname"
            variant="outlined"
            density="compact"
            :rules="hideAndShowFields? requiredFirstNameRules:[]"
            @input="verifyAddress"
            class="shipping-address-form"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-1" v-if="hideAndShowFields">
          <v-text-field
            label="Last Name"
            ref="lastName"
            v-model="lastname"
            variant="outlined"
            density="compact"
            :rules="hideAndShowFields? requiredLastNameRules:[]"
            v-show="hideAndShowFields"
            @input="verifyAddress"
            class="shipping-address-form"
          ></v-text-field>
          </v-col>
        <v-col cols="12" class="py-1">
          <v-text-field
            label="Street Address"
            ref="streetAddressOne"
            v-model="streetNoOne"
            variant="outlined"
            density="compact"
            required
            :rules="requiredStreetAddressRules"
            @input="verifyAddress"
            @change="checkPOBoxAddress()"
            class="shipping-address-form"
          ></v-text-field>
          </v-col>
        <v-col cols="12" class="py-1">
          <v-text-field
            label="Apt, Suite, Co., C/O etc."
            ref="streetAddressTwo"
            v-model="streetNoTwo"
            variant="outlined"
            density="compact"
            required
            @change="checkPOBoxAddress()"
            @input="verifyAddress"
            class="shipping-address-form"
          ></v-text-field>
          </v-col>
        <v-col cols="12" class="py-1">
          <v-text-field
            label="City"
            variant="outlined"
            density="compact"
            ref="city"
            :rules="requiredCityRules"
            v-model="city"
            @input="verifyAddress"
            class="shipping-address-form"
          ></v-text-field>
          </v-col>
        <v-col cols="12" class="py-1">
          <v-select
            :items="allCountries"
            density="compact"
            variant="outlined"
            label="Country"
            ref="country"
            v-model="countryId"
            item-value="value"
            item-title="label"
            @change="fetchStates(countryId)"
            :rules="[val => !!val || 'Please select the Country']"
            class="shipping-address-form"
          ></v-select>
          </v-col>
        <v-col cols="12" class="py-1">
          <v-text-field
            label="Zip/Postal Code"
            ref="zipCode"
            :rules="zipCodeRules"
            v-model="postcode"
            variant="outlined"
            density="compact"
            @input="verifyAddress"
            class="shipping-address-form"
          ></v-text-field>
      
          </v-col>
        <v-col cols="12" class="py-1">
          <v-select
            :items="regionList"
            density="compact"
            label="State/Province"
            v-model="regionId"
            item-title="label"
            item-value="value"
            variant="outlined"
            @change="checkRegionName()"
            :rules="[v => !!v || 'Please select the State']"
            class="shipping-address-form"
          ></v-select>
          </v-col>
        <v-col cols="12" class="py-1">
          <v-text-field
            label="Telephone"
            ref="telephone"
            :rules="phoneNumberRules"
            v-model="telephone"
            variant="outlined"
            density="compact"
            @input="acceptNumber"
            class="shipping-address-form"
          ></v-text-field>
       
          <span class="red--text caption ml-3" v-show="phoneNumberError">Telephone is required</span>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script> 
import { createHelpers } from "vuex-map-fields";
import { fetchStates } from "@/services/util.service"; 
const { mapFields } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField"
});
export default {
  name: "ShippingAddressFormComponent",
  props: ["hideAndShowFields"],
  data: () => ({
    setCountryCode: "US",
    phoneNumberError: false,
    confirmationDialog: false,
    newsletter: false,
    regionList: [],
    userDetails: true,
    c: "",
    valid: true,
    lazy: false,
    vTelInputProps: {
      dropdownOptions: {
        disabledDialCode: false
      },
      inputOptions: {
        showDialCode: true
      }
    },
   requiredFirstNameRules: [
    (v) => !!v || 'First Name field is required'
  ],
  requiredLastNameRules: [
    (v) => !!v || 'Last Name field is required'
  ],
    requiredStreetAddressRules: [
    (v) => !!v || 'Street Address is required',
    v => (v && v.length <= 35) || "This field should be less than 35 characters"
  ],
   requiredCityRules: [
    (v) => !!v || 'City is required'
  ],
    textRules: [
      v => !!v || "This field is required",
      v => (v && v.length >= 3) || "This field must be at least 3 characters"
    ],
    zipCodeRules: [
      v => !!v || "Zip code is required",
      v => (v && v.length >= 5) || "This field must be at least 5 characters"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    phoneNumberRules: [
      v => !!v || "Telephone is required",
      v => (v && v.length >= 8) || "Phone Number must be at least 8 characters"
    ]
  }),
 
  computed: {
    ...mapFields([
      "one",
   
      "address",
      "address.postcode",
      "address.telephone",
      "address.city",
      "address.countryId",
      "address.regionId",
      "address.region",
      "address.streetNoOne",
      "address.streetNoTwo",
      "address.firstname",
      "address.lastname"
    ]),
    allCountries: function() {
      return this.$store.state.accountOne.allCountries.map(countries => {
        return {
          value: countries.id,
          label: countries.fullNameEnglish
        };
      });
    },
    add: function() {
      return this.$store.state.accountOne.address;
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    },
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    }
  },
  watch: {
    countryId: function() {
      this.updateRegionList();
    },
    regionId: function() {
      this.fetchStates(this.countryId);
    },
    valid() {
      this.$emit("actionValid", this.valid);
    }
  },
  methods: {
    acceptNumber() {
        let x = this.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    verifyAddress() {
      this.$emit("verifyAddress");
    },
    checkRegionName() {
      if (this.regionId) {
        this.regionId = this.regionId.toString();

        let regionName = this.regionList.filter(obj => {
          if (obj.value == this.regionId) return obj;
        });
        this.$emit("regionDetails", regionName);
        this.$emit("verifyAddress");
      }
    },
    fetchStates(countryId) {
      
      this.regionList = fetchStates(countryId, this.countryAndStatesList);
      this.regionId = this.regionId ? this.regionId.toString(): '';
      if (this.regionId) {
        let regionName = this.regionList.filter(obj => {
          if (obj.value == this.regionId) return obj;
        });
        this.$emit("regionDetails", regionName);
        this.$emit("verifyAddress");
      }
      
    },
    updateRegionList() {
      if (this.regionId) {
        this.regionId = this.regionId.toString();
      }
      this.regionList = fetchStates(this.countryId, this.countryAndStatesList);
    },
    validateNumber(number, data) {
      if (data&&data.possible && data.valid) {
        this.phoneNumberError = false;
        this.telephone = "+" + data.country.dialCode +''+this.telephone
      }
      else {
        this.phoneNumberError = true;
        this.telephone  = ""
      }
    },
    async checkPOBoxAddress(){
      let street = [];
      street[0] = this.streetNoOne ? this.streetNoOne : "";
      if(this.streetNoTwo)
        street[1] = this.streetNoTwo ? this.streetNoTwo : "";
      let address = {
        street: street
      }
      await this.$store.dispatch("accountOne/checkPOBoxAddress", address);
    }
  },
  mounted(){
    if (this.$refs.shippingAddressform){
        this.$refs.shippingAddressform.resetValidation();
    }
  },
  async created() {
    await this.$store.dispatch("accountOne/fetchCountries");
    this.updateRegionList();
    await this.fetchStates(this.countryId);
    this.checkRegionName();
    if (this.valid) {
      this.$emit("actionValid", this.valid);
      this.$emit("verifyAddress");
    }
  }
};
</script>
<style src="./ShippingAddressFormComponent.scss" lang="scss"/>
