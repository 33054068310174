<template>
  <div>
    <v-tabs class="mt-6 myorders-orderview-tabs">
      <v-tab id="hideInPrint">Items Ordered</v-tab>
     </v-tabs>
     <v-window>
      <v-window-item value="one">
          <v-card tile flat>
          <v-card-text>
            <v-table id="hideInPrint">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left fontsize-16 font-weight-bold">Product Name</th>
                    <th class="text-left fontsize-16 font-weight-bold">Sku</th>
                    <th class="text-left fontsize-16 font-weight-bold">Price</th>
                    <th class="text-left fontsize-16 font-weight-bold">Qty</th>
                    <th class="text-left fontsize-16 font-weight-bold">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product,index) in viewOrderItem.items" v-bind:key="index" v-show="product.basePrice != 0">
                    <td>{{ product.name }}</td>
                    <td>{{ product.sku }}</td>
                    <td class="font-weight-bold">${{ priceFormatter(product.basePrice) }}</td>
                    <td>
                      <v-col class="pl-0">
                        <p class="mb-0">Ordered:{{ product.qtyOrdered }}</p>
                      </v-col>
                    </td>
                    <td class="font-weight-bold">${{ priceFormatter(product.baseRowTotalInclTax) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-table>
            <div class="order-table-border" id="showInPrint">
              <table>
                <h1 class="order-information-tab-heading">Items Ordered</h1>
                <v-divider></v-divider>
                <tr>
                  <th class="order-table-heading">Product Name</th>
                  <th class="order-table-heading">Sku</th>
                  <th class="order-table-heading">Price</th>
                  <th class="order-table-heading">Qty</th>
                  <th class="order-table-heading">Subtotal</th>
                </tr>
                <tr v-for="(product,index) in viewOrderItem.items" v-bind:key="index" v-show="product.basePrice != 0">
                  <td class="order-table-content">{{ product.name }}</td>
                  <td class="order-table-content">{{ product.sku }}</td>
                  <td class="order-table-content">
                    <b>${{ priceFormatter(product.basePrice) }}</b>
                  </td>
                  <td class="order-table-content">Ordered:{{ product.qtyOrdered }}</td>
                  <td class="order-table-content">
                    <b>${{ priceFormatter(product.baseRowTotalInclTax) }}</b>
                  </td>
                </tr>
              </table>
              <v-divider></v-divider>
              <v-alert class="grey lighten-4 mt-12" dense id="showInPrint">
                <v-row>
                  <v-col class="order-table-cloumnFirst">
                    <p class="mb-3">Subtotal</p>
                  </v-col>
                  <v-col class="order-table-cloumnSecond">
                    <p class="mb-3">${{priceFormatter(viewOrderItem.baseSubtotalInclTax)}}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="order-table-cloumnFirst">
                    <p class="mb-3">Shipping & Handling</p>
                  </v-col>
                  <v-col class="order-table-cloumnSecond">
                    <p class="mb-3">${{priceFormatter(viewOrderItem.baseShippingInclTax)}}</p>
                  </v-col>
                </v-row>
                <v-row v-show="viewOrderItem.discountAmount">
                  <v-col class="order-table-cloumnFirst">
                    <p class="mb-3">Discount</p>
                  </v-col>
                  <v-col class="order-table-cloumnSecond">
                    <p class="mb-3">${{priceFormatter(viewOrderItem.discountAmount)}}</p>
                  </v-col>
                </v-row>
                <v-row v-show="viewOrderItem.baseTaxAmount">
                  <v-col class="order-table-cloumnFirst">
                    <p class="mb-3">Tax</p>
                  </v-col>
                  <v-col class="order-table-cloumnSecond">
                    <p class="mb-3">${{priceFormatter(viewOrderItem.baseTaxAmount)}}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="order-table-cloumnFirst">
                    <p class="mb-3">
                      <b>Grand Total</b>
                    </p>
                  </v-col>
                  <v-col class="order-table-cloumnSecond">
                    <p class="mb-3">
                      <b>${{priceFormatter(viewOrderItem.grandTotal)}}</b>
                    </p>
                  </v-col>
                </v-row>
              </v-alert>
              <v-divider></v-divider>
            </div>
            <v-alert class="grey lighten-4 mt-12" dense id="hideInPrint">
              <v-row>
                <v-col cols="10" class="pb-0">
                  <p class="float-right mb-3">Subtotal</p>
                </v-col>
                <v-col cols="2" class="pb-0">
                  <p class="float-right mb-3">${{priceFormatter(viewOrderItem.baseSubtotalInclTax)}}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" class="pb-0">
                  <p class="float-right mb-3">Shipping & Handling</p>
                </v-col>
                <v-col cols="2" class="pb-0">
                  <p class="float-right mb-3">${{priceFormatter(viewOrderItem.baseShippingInclTax)}}</p>
                </v-col>
              </v-row>
              <v-row v-show="viewOrderItem.baseTaxAmount">
                <v-col cols="10" class="pb-0">
                  <p class="float-right mb-3">Tax</p>
                </v-col>
                <v-col cols="2" class="pb-0">
                  <p class="float-right mb-3">${{priceFormatter(viewOrderItem.baseTaxAmount)}}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" class="pb-0">
                  <p class="float-right font-weight-bold mb-3">Grand Total</p>
                </v-col>
                <v-col cols="2" class="pb-0">
                  <p class="float-right mb-3">${{priceFormatter(viewOrderItem.grandTotal)}}</p>
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
        </v-card>
        </v-window-item>
     </v-window>
 
    <v-col id="showInPrint">
      <h1 class="order-information-heading">Order Information</h1>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" v-if="viewOrderItem.extensionAttributes&&viewOrderItem.extensionAttributes.shippingAssignments">
          <div
            v-for="(details,index) in viewOrderItem.extensionAttributes.shippingAssignments"
            v-bind:key="index"
          >
            <p class="order-information-subheading mb-3">Shipping Address</p>

            <p class="order-information-details  mb-3" v-if="details.shipping&&details.shipping.address">
              {{details.shipping.address.firstname}}
              <span class="px-1"></span>
              {{details.shipping.address.lastname}}
            </p>
            <p class="order-information-details  mb-3" v-if="details.shipping&&details.shipping.address">{{details.shipping.address.street[0]}}</p>
            <p class="order-information-details  mb-3" v-if="details.shipping&&details.shipping.address">
              {{details.shipping.address.city}},
              <span class="px-1"></span>
              {{details.shipping.address.region}}
              <span class="px-1"></span>
              {{details.shipping.address.postcode}}
            </p>
            <p class="order-information-details  mb-3" v-if="details.shipping&&details.shipping.address">{{countryName(details.shipping.address.countryId)}}</p>
            <p
              class="order-information-details text-decoration-underline  mb-3" v-if="details.shipping&&details.shipping.address"
            >T:{{details.shipping.address.telephone}}</p>
          </div>
        </v-col>
        <v-col cols="12">
          <p class="order-information-subheading  mb-3">Shipping Method</p>
          <p class="order-information-details  mb-3">{{viewOrderItem.shippingDescription}}</p>
        </v-col>

        <v-col cols="12" v-if="viewOrderItem.billingAddress">
          <p class="order-information-subheading  mb-3">Billing Address</p>
          <p class="order-information-details  mb-3">
            {{viewOrderItem.billingAddress.firstname}}
            <span class="px-1"></span>
            {{viewOrderItem.billingAddress.lastname}}
          </p>
          <p class="order-information-details  mb-3">{{viewOrderItem.billingAddress.street[0]}}</p>
          <p class="order-information-details mb-3">
            {{viewOrderItem.billingAddress.city}},
            <span class="px-1"></span>
            {{viewOrderItem.billingAddress.region}}
            <span class="px-1"></span>
            {{viewOrderItem.billingAddress.postcode}}
          </p>
          <p
            class="order-information-details mb-3"
          >{{countryName(viewOrderItem.billingAddress.countryId)}}</p>
          <p
            class="order-information-details mb-3 text-decoration-underline"
          >T:{{viewOrderItem.billingAddress.telephone}}</p>
        </v-col>
        <v-col cols="12" v-if="viewOrderItem.payment">
          <p class="order-information-subheading mb-3">Payment Method</p>
          <p class="order-information-details mb-3">{{viewOrderItem.payment.additionalInformation[0]}}</p>
          <v-row>
            <v-col>
              <p class="order-information-details-creditcard mb-3">
                <b>Credit Card Type</b>
              </p>
            </v-col>
            <v-col>
              <p
                class="order-information-details-creditcard mb-3"
              >{{viewOrderItem.payment.additionalInformation[8]}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="order-information-details-creditcard mb-3">
                <b>Credit Card Number</b>
              </p>
            </v-col>
            <v-col>
              <p
                class="order-information-details-creditcard mb-3"
              >{{viewOrderItem.payment.additionalInformation[7]}}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import {  
  priceFormatter
} from "@/services/util.service";
export default {
  name: "ViewOrderTabs",
  props: ["viewOrderItem", "productInvoice"],
  data: () => ({
    viewOrder: false,
    priceFormatter: priceFormatter,
  }),
  components: {},
  computed: {
    hideAndShowFields: function() {
      let currentName = this.$route.name;
      if (currentName === "MyOrders") {
        return true;
      } else {
        return false;
      }
    },
    cartProductsItem() {
      return this.$store.state.meProductList.cartProductData;
    },
    userAuth: function() {
      return this.$store.state.auth.userAuth;
    },
    countryAndStatesList() {
      return this.$store.state.accountOne.allCountries;
    }
  },
  methods: {
    countryName(countryId) {
      if (countryId) {
        let name = this.countryAndStatesList.filter(
          country => country.id == countryId
        );
        if(name.length > 0){
        return name[0].fullNameEnglish;
        }
      }
    },
    viewMyOrder(item) {
      return (this.viewOrder = true) && (this.viewOrderItem = item);
    }
  },
  created() {
    this.$store.dispatch("accountOne/fetchCountries");
  }
};
</script>
<style src="./ViewOrderTabs.scss" lang="scss" scoped />
